<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <div slot="actions">
      <div class="field has-addons">
        <b-dropdown
          ref="dropdown"
          aria-role="list"
          position="is-bottom-left"
          :close-on-click="false"
          scrollable
          max-height="400px"
          append-to-body
        >
          <div slot="trigger">
            <b-button
              icon-left="dots-vertical"
              :size="$device.mobile ? 'is-small' : ''"
              type="is-default"
              rounded
            />
          </div>

          <b-dropdown-item
            v-if="$can('create', 'purchasing.carriers')"
            aria-role="listitem"
            has-link
          >
            <router-link to="/purchasing/carriers/create">
              <b-icon
                icon="plus"
                size="is-small"
              />
              {{ $t("create_carrier") }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <Card
      v-if="$can('read', 'purchasing.carriers')"
      content-class="c-table"
    >
      <b-table
        :data="items"
        :total="items.length"
        default-sort="id"
        default-sort-direction="asc"
        :per-page="pagination.perPage"
        :current-page="pagination.page"
        paginated
        hoverable
      >
        <b-table-column
          v-slot="props"
          field="id"
          :label="this.$i18n.t('id')"
          width="80"
          header-class="sticky-header"
          sortable
        >
          <router-link

            :to="`/purchasing/carriers/${props.row.id}`"
          >
            {{ props.row.id }}
          </router-link>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="name"
          :label="this.$i18n.tc('name', 1)"
          width="150"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.name }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="product"
          :label="this.$i18n.tc('product', 1)"
          width="150"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.product }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="type"
          :label="this.$i18n.t('type')"
          width="150"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.type ? props.row.type : "-" }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="ship_before"
          :label="this.$i18n.tc('ship_before', 1)"
          width="150"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.ship_before ? props.row.ship_before : "-" }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="rank"
          :label="this.$i18n.tc('rank', 1)"
          width="150"
          header-class="sticky-header"
          sortable
          centered
        >
          <span>
            {{ props.row.rank ? props.row.rank : "-" }}
          </span>
        </b-table-column>
        <!-- <b-table-column
          v-slot="props"
          field="memo"
          :label="this.$i18n.tc('memo', 1)"
          width="150"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.memo ? props.row.memo : "-" }}
          </span>
        </b-table-column> -->
        <b-table-column
          v-slot="props"
          field="proforma"
          :label="$t('invoice_car')"
          header-class="sticky-header"
          sortable
          centered
          width="40"
        >      
          <b-icon
            :icon=" props.row.proforma ? 'check' : 'close'"
            :type="props.row.proforma ? 'is-success' : 'is-danger'"
            size="is-small"
          />
        </b-table-column>
        <!-- <b-table-column
          v-slot="props"
          field="pickup"
          :label="$tc('pickup', 1)"
          header-class="sticky-header"
          sortable
          centered
          width="40"
        >      
          <b-icon
            :icon=" props.row.pickup ? 'check' : 'close'"
            :type="props.row.pickup ? 'is-success' : 'is-danger'"
            size="is-small"
          />
        </b-table-column> -->
        <b-table-column
          v-slot="props"
          field="active"
          :label="$tc('active', 1)"
          header-class="sticky-header"
          sortable
          centered
          width="40"
        >      
          <b-icon
            :icon=" props.row.active ? 'check' : 'close'"
            :type="props.row.active ? 'is-success' : 'is-danger'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="action"
          :label="$t('actions')"
          header-class="sticky-header"
          centered
          width="40"
        >
          <b-dropdown
            :key="props.row.iso"
            aria-role="list"
            position="is-bottom-left"
            append-to-body
          >
            <div slot="trigger">
              <b-icon
                icon="dots-vertical"
                size="is-small"
                class="is-clickable"
              />
            </div>

            <b-dropdown-item
              aria-role="listitem"
              @click="openCountryDrawer(props.row, false)"
            >
              <b-icon
                icon="eye"
                size="is-small"
              />
              {{ $t("view") }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="($can('delete', 'international.countries'))"
              aria-role="listitem"
              @click="deleteRestoreCarrier(props.row)"
            >
              <b-icon
                :icon="
                  props.row.active ? 'delete' : 'refresh'
                "
                :type="
                  props.row.active
                    ? 'is-danger'
                    : 'is-success'
                "
                size="is-small"
              />
              {{
                $t(
                  props.row.active
                    ? "delete"
                    : "restore"
                )
              }}
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </b-table>
    </Card>
  </Page>
</template>
  
  <script>
  export default { 
	data() {
	  return {
		rowsPerPage: [50, 100, 200],
        pagination: { perPage: 50, page: 1 },
		loading: false,
		items: [],
		total: 0,
	  };
	},
	created() {
	  	this.updateTitle(this.$i18n.tc("carrier", 2));
		this.getItems();
	},  
	methods: {
		getItems() {
			if (!this.$can("read", "purchasing.carriers")) return;
			this.loading = true;
			this.$axios
				.get(`/carriers`)
				.then(res  => {
					this.items = res.data.list;	
					this.total = res.data.pagination.total;				
				})
				.catch(e => this.$root.clientError(e))
				.finally(() => (this.loading = false));
		},
		deleteRestoreCarrier(carrier) {
			if (carrier.active) {
				this.$axios
				.delete(`/carriers/${carrier.id}`)
				.then(res  => {
					carrier.active = false;
					this.$root.notify(this.$t("successfully_deleted"), "is-success");
				})
				.catch(e => this.$root.clientError(e))
				.finally(() => (this.loading = false));
			}
			else {
				this.$axios
				.patch(`/carriers/${carrier.id}/restore`)
				.then(res  => {
					carrier.active = true;
					this.$root.notify(this.$t("successfully_restored"), "is-success");
				})
				.catch(e => this.$root.clientError(e))
				.finally(() => (this.loading = false));
			}
		}
	}
  };
  </script>